.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #ED7E00;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(237, 126, 0, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #5a5a5a;
  font-family: "TexGyreHeros", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.5rem + 1
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.5rem;
  color: #ED7E00;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.5rem + 1
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.5rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.25rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * -10);
  font-size: 1.25rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.875rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.125rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.125rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.5rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.25rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1.125rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 1.125rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #ED7E00;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #5a5a5a;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(237, 126, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #5a5a5a;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #ED7E00;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #5a5a5a;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(237, 126, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #a7a7a7;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #a7a7a7;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f3f3f3;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #ED7E00;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #a7a7a7;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #a7a7a7;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #a7a7a7;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.125rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #5a5a5a;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #9a9a9a;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #9a9a9a;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #ED7E00;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #ED7E00;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #5a5a5a;
  font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #a7a7a7;
  border-radius: 0 0 0.75rem 0;
  box-shadow: 0 0 0 #a7a7a7 inset;
  color: #5a5a5a;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #ED7E00;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
.input:focus,
.textarea:focus {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3.375rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #ED7E00;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
.control input:focus ~ .indicator {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #a7a7a7;
  border-radius: 0 0 0.75rem 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #5a5a5a;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #a7a7a7;
  border-radius: 0 0 0.75rem 0;
  box-shadow: 0 0 0.1875rem #a7a7a7 inset;
  color: #5a5a5a;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #5a5a5a;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #ED7E00;
}
.select.error.focus {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
.select.focus {
  border-color: #ED7E00;
  box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
  color: #5a5a5a;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #5a5a5a;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 3.25rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 3.25rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #5a5a5a;
}
select:focus::-ms-value {
  background: none;
  color: #5a5a5a;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(237, 126, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(237, 126, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(237, 126, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(237, 126, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .calendar_header {
    text-align: center;
  }
  .calendar_header .calendar_headline {
    display: inline-block;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .calendar_header .calendar_previous_month,
  .calendar_header .calendar_next_month {
    color: #5a5a5a;
    text-decoration: none;
  }
  .calendar_header .calendar_previous_month:hover,
  .calendar_header .calendar_next_month:hover {
    text-decoration: underline;
  }
  .calendar_header .calendar_previous_month:before {
    content: "« ";
  }
  .calendar_header .calendar_next_month:after {
    content: " »";
  }
  .calendar_days {
    background: white;
    border-top: 0.0625rem solid white;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none !important;
    padding: 0;
  }
  .calendar_days li {
    border: 0.0625rem solid white;
    border-width: 0 0 0.0625rem 0.0625rem;
    color: #999999;
    font-size: 75%;
    min-height: 5rem;
    padding: 0.375em 0.75em;
    position: relative;
    text-align: right;
    width: 14.28%;
  }
  .calendar_days li.current_month {
    background: #e0e0e0;
    color: #5a5a5a;
  }
  .calendar_days li.current_day {
    /*
            border: (@border_width * 2) solid @text_color;
            margin: (@border_width * -2) (@border_width * -2) 0 0;
*/
    z-index: 1;
  }
  .calendar_days li.current_day:before {
    border: 0.125rem solid #5a5a5a;
    bottom: -0.0625rem;
    content: "";
    left: -0.0625rem;
    position: absolute;
    right: -0.0625rem;
    top: -0.0625rem;
  }
  .calendar_days li.booked {
    -webkit-box-align: stretch;
            align-items: stretch;
    background: #ffdfba;
    display: -webkit-box;
    display: flex;
  }
  .calendar_days li.booked a {
    color: #999999;
    display: -webkit-box;
    display: flex;
    flex-basis: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
    text-decoration: none;
  }
  .calendar_days li.booked a:before {
    border-bottom: 0.3125rem solid #5a5a5a;
    border-right: 0.3125rem solid #5a5a5a;
    content: "";
    display: none;
    height: 0.75rem;
    left: 40%;
    position: absolute;
    top: 35%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 0.375rem;
  }
  .calendar_days li.booked a:hover:before {
    border-color: white;
    display: block;
  }
  .calendar_days li.booked a.active:before {
    border-color: #5a5a5a;
    display: block;
  }
  .calendar_days li.booked .calendar_event {
    color: #999999;
    display: block;
    margin-left: 0.375em;
  }
  .calendar_days li.booked .calendar_event:before {
    border: 0.0625rem solid #999999;
    border-radius: 100%;
    content: "";
    display: inline-block;
    height: 0.625rem;
    width: 0.625rem;
  }
  .calendar_days li.booked .calendar_event .text {
    display: none;
  }
  .calendar_days li.booked .calendar_entry {
    align-content: flex-end;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .calendar_days li.current_month.booked {
    background: #ED7E00;
    color: white;
  }
  .calendar_days li.current_month.booked a {
    color: white;
  }
  .calendar_days li.current_month.booked .calendar_event {
    color: white;
  }
  .calendar_days li.current_month.booked .calendar_event:before {
    border-color: white;
  }
  .calendar_days li span {
    display: block;
  }
  .calendar_days {
    margin-left: -1em;
    margin-right: -1em;
  }
  .events_overview.calendar {
    padding-top: 1.5em;
  }
  .events_overview.calendar > div {
    display: none;
    margin-bottom: 0.75em;
  }
  .events_overview.calendar > div.active {
    display: -webkit-box;
    display: flex;
  }
  .webinar_calendar {
    margin-bottom: 3em;
  }
  .events_overview .entry {
    display: -webkit-box;
    display: flex;
    margin: 1.5em 0;
  }
  .events_overview .entry .content {
    margin-top: 0;
    padding-top: 0;
  }
  .events_overview .entry h3 {
    padding-top: 0;
  }
  .events_overview .entry h3 .small_title {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    display: block;
    font-family: "TexGyreHeros", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  .events_overview a {
    color: #5a5a5a;
    text-decoration: none;
  }
  .events_overview .date {
    align-content: center;
    background: white;
    border: 0.0625rem solid #ED7E00;
    border-radius: 0 0 1.125rem 0;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    height: 5.0625rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    width: 5.0625rem;
    z-index: 20;
  }
  .events_overview .date span {
    display: block;
    text-align: center;
  }
  .events_overview .day {
    font-size: 250%;
    font-weight: bold;
    line-height: 1;
  }
  .events_overview .month_year {
    font-size: 80%;
  }
  .events_overview .content {
    background: white;
    margin: 1.5em 0 0 -3em;
    padding: 0.75em 0.75em 0.75em 4.5em;
    width: 100%;
  }
  .events_overview .event_detail strong {
    display: block;
  }
  .events_overview .export_event {
    display: block;
  }
  .event_content .early_bird {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .event_content .early_bird .image {
    display: block;
    margin-right: 0.75em;
  }
  .event_content .early_bird .text {
    display: block;
  }
  .events_different_years {
    border-top: 0.3125rem solid #EDEDED;
    margin-bottom: 3em;
    padding-top: 1.5em;
  }
  .events_different_years ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .events_different_years li {
    margin: 0 1.5em 1.5em 0;
  }
  .events_different_years li a {
    margin-bottom: 0;
  }
  .order_seminar_documents .formular .button_wrapper {
    text-align: left;
  }
  .order_more_documents .quantity {
    max-width: 5rem;
  }
  .persaldo_news_overview ul {
    background: none;
    padding: 0;
  }
  .persaldo_news_overview .persaldo_item {
    display: -webkit-box;
    display: flex;
    list-style: none;
    margin-bottom: 1.5em;
  }
  .persaldo_news_overview .content {
    background: #EDEDED;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .persaldo_news_overview .issue {
    margin-bottom: 0;
    text-align: right;
  }
  .persaldo_news_overview .issue a {
    background: #fff;
    border: 0.0625rem solid #EDEDED;
    display: inline-block;
    padding: 0.5em 0.75em;
    text-decoration: none;
  }
  .persaldo_news_overview .description {
    padding: 0 1.5em 1.5em 1.5em;
  }
  .persaldo_news_overview .button.ghost_button {
    background: white;
  }
  .persaldo_overview.accordion [role="tab"],
  .events_overview.accordion [role="tab"] {
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-left: 0.375em;
  }
  .persaldo_overview.accordion [role="tab"] .title,
  .events_overview.accordion [role="tab"] .title {
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    position: relative;
  }
  .persaldo_overview.accordion [role="tab"] .title .icon,
  .events_overview.accordion [role="tab"] .title .icon {
    fill: #5a5a5a;
    left: 0;
    position: absolute;
  }
  .persaldo_overview.accordion [role="tab"] .title .text,
  .events_overview.accordion [role="tab"] .title .text {
    padding-left: 1.8em;
  }
  .persaldo_overview.accordion [role="tab"][aria-expanded="true"] .icon,
  .events_overview.accordion [role="tab"][aria-expanded="true"] .icon {
    fill: #ED7E00;
  }
  .persaldo_overview.accordion [role="tab"]:hover .icon,
  .events_overview.accordion [role="tab"]:hover .icon {
    fill: #ED7E00;
  }
  .persaldo_overview.accordion [role="tabpanel"][aria-hidden="false"],
  .events_overview.accordion [role="tabpanel"][aria-hidden="false"] {
    margin: 0;
  }
  .persaldo_overview.accordion a,
  .events_overview.accordion a {
    color: #5a5a5a;
  }
  .persaldo_overview.accordion a.button,
  .events_overview.accordion a.button {
    color: #000000;
  }
  .persaldo_overview.accordion .flippingbook,
  .events_overview.accordion .flippingbook {
    border: 0.0625rem solid #EDEDED;
    display: block;
  }
  .persaldo_overview.accordion .buttons,
  .events_overview.accordion .buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0 0 1.5em 0;
  }
  .persaldo_overview.accordion .entry_text,
  .events_overview.accordion .entry_text {
    margin-bottom: 1.5em;
  }
  .persaldo_overview.accordion .entry_text .row,
  .events_overview.accordion .entry_text .row {
    padding: 0.75em;
  }
  .persaldo_overview.accordion .entry_text .row:nth-child(odd),
  .events_overview.accordion .entry_text .row:nth-child(odd) {
    background: #EDEDED;
  }
  .persaldo_issue a {
    color: #5a5a5a;
  }
  .persaldo_issue a.button {
    color: #000000;
  }
  .persaldo_issue .flippingbook {
    border: 0.0625rem solid #EDEDED;
    display: block;
  }
  .persaldo_issue .buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0 0 1.5em 0;
  }
  .persaldo_issue .news_entries .row {
    padding: 0.75em;
  }
  .persaldo_issue .news_entries .row:nth-child(odd) {
    background: #EDEDED;
  }
  .persaldo_archive ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .persaldo_archive li {
    margin: 0 1.5em 1.5em 0;
  }
  .persaldo_archive a {
    display: block;
    text-decoration: none;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .persaldo_archive a .icon {
    -webkit-box-align: center;
            align-items: center;
    background: #EDEDED;
    background-size: cover;
    border: 0.0625rem solid #5a5a5a;
    display: -webkit-box;
    display: flex;
    height: 7.5rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    width: 5.3125rem;
  }
  .persaldo_archive a .icon:before {
    border: 0.625rem solid #595959;
    border-right-color: white;
    border-top-color: white;
    box-shadow: -0.1875rem 0 0 0 white, 0 0.1875rem 0 0 white;
    content: "";
    position: absolute;
    right: -0.0625rem;
    top: -0.0625rem;
  }
  .persaldo_archive a .icon.pdf:after {
    background: rgba(255, 255, 255, 0.85);
    bottom: 0;
    content: "PDF";
    font-size: 80%;
    height: 1.4375rem;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
  }
  .persaldo_archive a .text {
    display: block;
    text-align: center;
  }
  .persaldo_archive a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .persaldo_archive_title {
    border-top: 0.3125rem solid #EDEDED;
    margin-top: 1.5em;
  }
  main .persaldo_archive li a[href^="http"][target="_blank"]:not(.share_button)::after {
    display: none;
  }
  .seminar_documents_overview h2 {
    margin-bottom: 1.5em;
  }
  .seminar_documents_overview .transcripts_audiofiles_entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .transcripts_audiofiles_entry {
    border-bottom: 0.3125rem solid #EDEDED;
    margin-bottom: 1.5em !important;
    padding-bottom: 1.5em;
  }
  .transcripts_audiofiles_entry p {
    margin-bottom: 0;
  }
  .transcripts_audiofiles_entry ul {
    list-style: none !important;
    margin: 0.75em 0 0 0;
    padding: 0;
  }
  .transcripts_audiofiles_entry ul > li {
    padding: 0;
  }
  .transcripts_audiofiles_entry a {
    -webkit-box-align: center;
            align-items: center;
    border-radius: 0 0 0.75rem 0;
    display: -webkit-box;
    display: flex;
    padding: 0.625rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .transcripts_audiofiles_entry a:hover {
    background: #ED7E00;
  }
  .transcripts_audiofiles_entry a:hover .icon {
    fill: #fff;
  }
  .transcripts_audiofiles_entry .icon {
    display: inline-block;
    fill: #ED7E00;
    flex-shrink: 0;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .transcripts_audiofiles_entry .teaser_icon .icon {
    fill: #5a5a5a;
  }
  .transcripts_audiofiles_entry p.description {
    display: none;
    font-size: 80%;
  }
  .transcripts_audiofiles_entry .text {
    color: #5a5a5a;
    padding-left: 0.75em;
  }
  .transcripts_audiofiles_entry .title {
    padding-top: 0;
  }
  .transcripts_audiofiles_entry .seminar_documents_image {
    display: none;
  }
  main .transcripts_audiofiles_entry li a:after {
    display: none !important;
  }
  .enter_download_code {
    margin-bottom: 3em;
  }
  .explanation_abo .icon_read_online,
  .explanation_abo .icon_audio {
    padding-left: 2.1875rem;
    position: relative;
  }
  .explanation_abo .icon_read_online:before,
  .explanation_abo .icon_audio:before {
    background: no-repeat center;
    content: "";
    display: block;
    height: 1.5625rem;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 1.5625rem;
  }
  .explanation_abo .icon_read_online:before {
    background-image: url("../images/read_document.svg");
  }
  .explanation_abo .icon_audio:before {
    background-image: url("../images/sound.svg");
  }
  .survey_widget {
    margin-top: 2rem;
    padding: 1.5em 0;
  }
  .survey_widget .image {
    background-size: cover;
    display: block;
    padding-bottom: 100%;
    width: 100%;
  }
  .survey_answer {
    background: white;
    border: 0.3125rem solid white;
  }
  .survey_answer .percent_bar {
    background: #ED7E00;
    border-radius: 0.3125rem;
    display: block;
    height: 0.625rem;
  }
  .survey_answer .text {
    background: white;
    text-align: center;
  }
  .survey_result {
    display: -webkit-box;
    display: flex;
  }
  .survey_form form,
  .survey_answer {
    margin-left: 1rem;
    width: 50%;
  }
  .percent_bar_container {
    background: white;
    padding: 0.25rem;
  }
  .survey_form .button {
    background: transparent;
    border: 0;
    display: block;
    height: auto;
    padding: 0;
    width: 100%;
  }
  .survey_form .button .button_text {
    background: #ED7E00;
    border-radius: 0 0 0.75rem 0;
    display: -webkit-box;
    display: flex;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .survey_form .button:hover .button_text {
    background: #477A8E;
  }
  .survey_form .another_answer {
    display: none;
  }
  .last_survey_cta {
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    text-decoration: none;
  }
  .last_survey_cta:before {
    border: 0 solid #ED7E00;
    border-bottom-width: 0.3125rem;
    border-right-width: 0.3125rem;
    content: "";
    display: block;
    flex-shrink: 0;
    height: 0.75rem;
    margin: 0.375em 0.375em 0 -0.375em;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: margin 300ms;
    transition: margin 300ms;
    width: 0.75rem;
  }
  .last_survey_cta:hover {
    text-decoration: underline;
  }
  .last_survey_cta:hover:before {
    margin: 0.375em 0 0 0;
  }
  .last_survey_cta span {
    display: block;
    margin-left: 0.5em;
  }
  .survey_footer {
    background: #EDEDED;
  }
  .survey_form .button:active,
  .survey_form .button.active {
    border: 0;
    display: block;
  }
  .startpage .survey_widget {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .startpage .survey_answer {
    border: 0;
  }
  .webinar_overview h2 {
    margin-bottom: 1.5em;
  }
  .webinar_overview .webinar_entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .webinar_overview .transcripts_audiofiles_entry {
    padding-bottom: 0;
  }
  /*
.webinar_login {
    .webinar_overview {

        & > div {
            display: flex;
        }

        .transcripts_audiofiles_entry {
            display: flex;
            flex-direction: row;

            .webinar_image {
                margin-right: @spacing;
            }

            .webinar_ov_content {
                padding-right: @spacing;
            }
        }
    }
}
*/
  .webinar_entry {
    border-bottom: 0.3125rem solid #EDEDED;
    margin-bottom: 1.5em !important;
    padding-bottom: 1.5em;
  }
  .webinar_entry p {
    margin-bottom: 0;
  }
  .webinar_entry ul {
    list-style: none !important;
    margin: 0.75em 0 0 0;
    padding: 0;
  }
  .webinar_entry ul > li {
    padding: 0;
  }
  .webinar_entry .button_wrapper {
    display: inline-block;
    margin-top: 0.75em;
  }
  .webinar_entry a {
    -webkit-box-align: center;
            align-items: center;
    border-radius: 0 0 0.75rem 0;
    display: -webkit-box;
    display: flex;
    padding: 0.625rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .webinar_entry a:hover {
    background: #ED7E00;
  }
  .webinar_entry a:hover .text {
    color: white;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .webinar_entry a:hover .icon {
    fill: #fff;
  }
  .webinar_entry .icon {
    display: inline-block;
    fill: #ED7E00;
    flex-shrink: 0;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .webinar_entry .teaser_icon .icon {
    fill: #5a5a5a;
  }
  .webinar_entry p.description {
    display: none;
    font-size: 80%;
  }
  .webinar_entry .text {
    color: #5a5a5a;
    padding-left: 0.75em;
  }
  .webinar_entry .title {
    padding-top: 0;
  }
  .webinar_entry .webinar_image {
    display: none;
  }
  main .webinar_entry li a:after {
    display: none !important;
  }
  .accordion.webinar_selection.seperate_menu {
    display: -webkit-box;
    display: flex;
    /*
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-left: (-@column_margin_min / 2);
    margin-right: (-@column_margin_min / 2);

    @media (min-width: @xsmall_grid_breakpoint) {
        margin: 0 @column_margin_2_calc;
    }

    @media (min-width: @xxxlarge_grid_breakpoint) {
        margin: 0 (-@column_margin_max / 2);
    }

    .seperate_menu, .accordion_content {
        flex-basis: 50%;
        max-width: 50%;
        padding-left: (@column_margin_min / 2);
        padding-right: (@column_margin_min / 2);

        @media (min-width: @xsmall_grid_breakpoint) {
            padding: 0 @column_padding_2_calc;
        }

        @media (min-width: @xxxlarge_grid_breakpoint) {
            padding: 0 (@column_margin_max / 2);
        }
    }
*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
  }
  .accordion.webinar_selection.seperate_menu .seperate_menu {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .accordion.webinar_selection.seperate_menu .seperate_menu h2 {
    flex-basis: 50%;
    max-width: 50%;
    min-width: auto;
  }
  .bigmarker-widget-out-box {
    max-width: 37.5rem;
    width: auto !important;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #a7a7a7;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-family: "TexGyreHeros", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    display: -webkit-box;
    display: flex;
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #ED7E00;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #ED7E00;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:hover {
    background: #EDEDED;
  }
  [role="tab"]:hover .icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  [role="tab"]:focus {
    border-color: #ED7E00;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #ED7E00;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .accordion [role="tab"] {
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-left: 0.375em;
  }
  .accordion [role="tab"] .title {
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    position: relative;
  }
  .accordion [role="tab"] .title .icon {
    fill: #5a5a5a;
    left: 0;
    position: absolute;
  }
  .accordion [role="tab"] .title .text {
    padding-left: 1.8em;
  }
  .accordion [role="tab"][aria-expanded="true"] .icon {
    fill: #ED7E00;
  }
  .accordion [role="tab"]:hover .icon {
    fill: #ED7E00;
  }
  .accordion [role="tabpanel"][aria-hidden="false"] {
    margin: 0;
  }
  .accordion a {
    color: #5a5a5a;
  }
  .accordion a.button {
    color: #000000;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  aside {
    padding-top: 3em;
    position: relative;
  }
  .atikon_logo {
    height: 100%;
  }
  /*
.author_hor {
    display: inline-block;
    fill: @text_color;
    height: (9 / @rem);
    line-height: (9 / @rem);
    width: (75 / @rem);
}
*/
  .author_ver {
    left: 0.1875rem;
    top: 0.3125rem;
    display: inline-block;
    fill: #8eb7c7;
    height: 4.6875rem;
    line-height: 4.6875rem;
    position: absolute;
    width: 0.5625rem;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .button {
    border-radius: 0 0 0.75rem 0;
    margin-bottom: 0.75em;
    padding: 0 1.5em;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    outline: 0;
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #ED7E00 inset;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button.ghost_button {
    background: #EDEDED;
    border: 0.0625rem solid #EDEDED;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button.ghost_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button.ghost_button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button.ghost_button .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .button.ghost_button .icon + .text {
    padding-left: 0.5em;
  }
  .button.ghost_button:hover {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .button.ghost_button:hover .icon {
    fill: #fff;
  }
  .button.ghost_button:active,
  .button.ghost_button.active {
    box-shadow: 0 0 0.1875rem #ED7E00 inset;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button.ghost_button:active > span,
  .button.ghost_button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button.ghost_button:active .icon,
  .button.ghost_button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button.ghost_button:active .text,
  .button.ghost_button.active .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .button.ghost_button:active .icon + .text,
  .button.ghost_button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button.additional_bottom_margin {
    margin-bottom: 1.5em;
  }
  .button.fullwidth {
    display: block;
  }
  .button_overview .overview_title {
    padding-top: 0;
  }
  .button_overview ul {
    padding-left: 0;
  }
  .button_overview li {
    list-style: none;
    padding-left: 0;
  }
  .button_overview .entry.touch_link {
    background: #EDEDED;
    border: 0.0625rem solid #EDEDED;
    border-radius: 0 0 0.75rem 0;
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
    padding: 0 1.5em;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
  }
  .button_overview .entry.touch_link .title {
    -webkit-box-align: center;
            align-items: center;
    color: #000000;
    display: -webkit-box;
    display: flex;
    font-weight: bold;
    margin: 0;
    text-decoration: none;
    min-height: 2.625rem;
  }
  .button_overview .entry.touch_link:hover {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .button_overview .entry.touch_link:hover .icon {
    fill: #fff;
  }
  .button_overview .entry.touch_link:hover .title {
    color: #fff;
  }
  .button_overview .link_entry {
    background: #EDEDED;
    border: 0.0625rem solid #EDEDED;
    border-radius: 0 0 0.75rem 0;
    color: #000000;
    display: block;
    font-weight: bold;
    margin-bottom: 1.5em;
    padding: 0 1.5em;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    line-height: 2.625rem;
  }
  .button_overview .link_entry:hover {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .button_overview .link_entry:hover .icon {
    fill: #fff;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #ED7E00;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #ED7E00;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .company_logo {
    width: 6.875rem;
    display: block;
    margin-bottom: -5vw;
  }
  .cookie_bg {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999999;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #efefef;
    display: block;
    left: 50%;
    max-height: 80vh;
    max-width: 80%;
    overflow: auto;
    padding: 1.5rem;
    position: fixed;
    top: 2rem;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    /*
    p {
        margin: 0;
    }
*/
  }
  .cookie_banner .cookie_banner_opt_in_inputs {
    padding: 1rem 0 0 0;
  }
  .cookie_banner .cookie_banner_opt_in_inputs .cookie_banner_opt_in_input {
    display: inline-block;
  }
  .cookie_banner .cookie_banner_opt_in_inputs input {
    margin-right: 1rem;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  .cookie_banner .actions {
    text-align: right;
  }
  .cookie_banner .actions a {
    margin-bottom: 1rem;
    margin-left: 1rem;
    max-width: 100%;
  }
  .cookie_banner .actions a > span {
    height: auto;
  }
  .cookie_banner .actions a > span .text {
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
  }
  .cookie_banner .like_h2 {
    display: inline-block;
    font-size: 1.5em;
    margin-bottom: 0.5rem;
    word-break: break-all;
  }
  .cookie_banner .consent_banner_inputs {
    display: -webkit-box;
    display: flex;
  }
  .cookie_banner .consent_banner_input {
    margin-right: 1rem;
  }
  .cookie_banner [role="tablist"] {
    margin: 1rem 0 0 0;
  }
  .cookie_banner [role="tab"] {
    border-top: 0;
  }
  .cookie_banner [role="tab"] .title {
    font-size: 0.8em;
    margin-right: 1em;
    text-align: right;
  }
  .cookie_banner [role="tab"] .icon {
    display: block;
    fill: #ED7E00;
    height: 0.5em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 0.5em;
  }
  .cookie_banner [role="tabpanel"] p {
    font-size: 0.9em;
    margin-bottom: 1rem;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    font-family: "TexGyreHeros", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .download.centered_link .file {
    height: auto;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .download.centered_link .icon {
    display: none;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:hover > a,
  .dropdown_menu > ul li.hover > a {
    color: #ED7E00;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul li a {
    text-decoration: none;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 0.75em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    border-radius: 0 0.75rem 0 0;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
    font-size: 1.35em;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0 0.75em 0.5em 0.75em;
    position: relative;
    text-transform: uppercase;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a:before {
    border-left: 0.125rem solid white;
    bottom: 0;
    content: "";
    left: -0.125rem;
    position: absolute;
    top: 20%;
    -webkit-transition: border-color 300ms, top 300ms;
    transition: border-color 300ms, top 300ms;
  }
  .dropdown_menu > ul > li > a.active {
    background: white;
    color: #ED7E00;
  }
  .dropdown_menu > ul > li > a.active:before {
    top: 0;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    background: #EDEDED;
  }
  .dropdown_menu > ul > li:hover > a:before,
  .dropdown_menu > ul > li.hover > a:before {
    top: 0;
  }
  .dropdown_menu > ul > li ul {
    background: #f3f3f3;
    opacity: 0;
    padding: 0.75em 0;
    position: absolute;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0.375em 1.12781955em;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #ED7E00;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #ED7E00;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.75em;
  }
  footer[role="contentinfo"] .dark_background {
    background: #3E3E3E;
    color: #fff;
    margin-bottom: 0.75em;
    padding: 1.5em 0;
    text-align: center;
  }
  footer[role="contentinfo"] .dark_background a {
    color: #fff;
    -webkit-text-decoration-color: #878787;
            text-decoration-color: #878787;
  }
  footer[role="contentinfo"] .dark_background .button {
    color: #000000;
  }
  footer[role="contentinfo"] .dark_background .button:hover {
    color: #fff;
  }
  footer[role="contentinfo"] .dark_background .ghost_button,
  footer[role="contentinfo"] .dark_background .address_vcard_button {
    color: #fff;
  }
  footer[role="contentinfo"] .style_h2 {
    color: #fff;
    margin-bottom: 0;
  }
  footer[role="contentinfo"] p {
    margin-bottom: 1.5em;
  }
  footer[role="contentinfo"] .footer_box {
    border-top: 0.3125rem solid #595959;
    margin-bottom: 1.5em;
    padding-top: 1.5em;
  }
  footer[role="contentinfo"] .footer_box:first-child {
    border: 0;
    padding-top: 0;
  }
  footer[role="contentinfo"] .footer_box .button {
    margin-bottom: 0;
  }
  footer[role="contentinfo"] .footer_icon {
    padding-top: 0.75em;
  }
  footer[role="contentinfo"] .icon_location,
  footer[role="contentinfo"] .icon_team,
  footer[role="contentinfo"] .icon_newsletter {
    background: transparent;
    border: 0;
  }
  footer[role="contentinfo"] .icon_location .icon,
  footer[role="contentinfo"] .icon_team .icon,
  footer[role="contentinfo"] .icon_newsletter .icon {
    fill: #595959;
    height: 3.5rem;
    width: 3.1875rem;
  }
  footer[role="contentinfo"] .icon_location .text,
  footer[role="contentinfo"] .icon_team .text,
  footer[role="contentinfo"] .icon_newsletter .text {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  footer[role="contentinfo"] .button {
    margin-bottom: 1.5em;
  }
  footer[role="contentinfo"] .ghost_button,
  footer[role="contentinfo"] .address_vcard_button {
    background-color: #595959;
    border-color: #595959;
  }
  footer[role="contentinfo"] .ghost_button:hover,
  footer[role="contentinfo"] .address_vcard_button:hover {
    background-color: #ED7E00;
    border-color: #ED7E00;
    color: #000000;
  }
  footer[role="contentinfo"] .address_vcard_button {
    margin-top: 0;
  }
  footer[role="contentinfo"] .contact {
    display: inline;
    position: relative;
  }
  footer[role="contentinfo"] .contact strong {
    font-weight: 400;
    min-width: 0;
  }
  footer[role="contentinfo"] .contact.contact_email strong {
    display: none;
  }
  footer[role="contentinfo"] .contact:first-child {
    margin-right: 0.5em;
    padding-right: 0.5em;
  }
  footer[role="contentinfo"] .contact:first-child:after {
    border-right: 0.0625rem solid #fff;
    bottom: 0.25em;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0.25em;
  }
  footer[role="contentinfo"] .contact_wrapper {
    display: block;
  }
  footer[role="contentinfo"] .contact_wrapper p {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0;
  }
  footer[role="contentinfo"] .contact_wrapper .postal_address {
    margin-bottom: 0;
  }
  footer[role="contentinfo"] .contact_wrapper .postal_address > span {
    position: relative;
  }
  footer[role="contentinfo"] .contact_wrapper .postal_address > span:first-child {
    margin-right: 0.5em;
    padding-right: 0.5em;
  }
  footer[role="contentinfo"] .contact_wrapper .postal_address > span:first-child:after {
    border-right: 0.0625rem solid #fff;
    bottom: 0.25em;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0.25em;
  }
  footer[role="contentinfo"] .related_links ul {
    line-height: 2.0625rem;
    margin-bottom: 1.5em;
    text-align: center;
  }
  footer[role="contentinfo"] .related_links ul a {
    color: #5a5a5a;
    display: inline-block;
    text-decoration: none;
  }
  footer[role="contentinfo"] .related_links ul a:hover {
    text-decoration: underline;
  }
  .form_disclaimer {
    background: #f3f3f3;
    border-radius: 0 0 0.75rem 0;
    color: #5a5a5a;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #ED7E00;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #a7a7a7;
    border-radius: 0 0 0.75rem 0 0 0 0 0 0.75rem 0;
    box-shadow: 0 0 0.1875rem #a7a7a7 inset;
    color: #5a5a5a;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0.75rem 0 0 0 0.75rem 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file .file_button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #ED7E00 inset;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .birth_date .tooltip_wrapper {
    display: -webkit-box;
    display: flex;
  }
  .birth_date .tooltip_wrapper input {
    margin-right: 1.5em;
  }
  .birth_date .tooltip_wrapper input.last {
    margin-right: 0;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #f3f3f3;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #ED7E00;
    border-color: #ED7E00;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    z-index: 15;
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #a7a7a7;
    color: #5a5a5a;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #5a5a5a;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #5a5a5a;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0.75rem 0 0 0 0 0 0.75rem 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0.75rem 0 0 0 0.75rem 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0 0 0.75rem 0;
    color: #ED7E00;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #a7a7a7;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #a7a7a7;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  body > header {
    background: #477A8E;
    position: relative;
    z-index: 200;
  }
  .header_container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .functions_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-bottom: -5%;
    padding-bottom: 2.75rem;
  }
  .login_link {
    padding: 0.625rem;
  }
  .login_link .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    position: relative;
    width: 2.125rem;
  }
  .login_link .text {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .header_content {
    background: #EDEDED;
    position: relative;
  }
  .wahl_banner {
    width: 100%;
    bottom: 0;
    background-color: #477A8E;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: anchor-center;
            align-items: anchor-center;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .wahl_banner .headline {
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .wahl_banner .headline h2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.5rem;
    font-weight: 400;
    text-wrap: balance;
    text-align: center;
    color: #fff;
  }
  .wahl_banner .headline h2 .text_white {
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
  }
  .wahl_banner a {
    margin-bottom: 0;
  }
  .wahl_banner a {
    -webkit-text-decoration-color: #878787;
            text-decoration-color: #878787;
  }
  .wahl_banner .dark_button {
    background-color: #3E3E3E;
    color: #fff;
    border-color: #3E3E3E;
    margin-bottom: 1.5em;
  }
  .wahl_banner .dark_button:hover {
    color: #000000;
    border-color: #EDEDED;
    background-color: #EDEDED;
  }
  .wahl_banner .banner_button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    height: 100%;
    -webkit-box-align: center;
            align-items: center;
  }
  main li a[href^="http"][target="_blank"]:not(.share_button)::after,
  main p:not(.form_disclaimer) a[href^="http"][target="_blank"]:not(.share_button)::after {
    background-image: url("../images/external_link.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0.875rem 0.875rem;
    content: "";
    display: inline-block;
    height: 1.25rem;
    padding-right: 1.25rem;
  }
  .image_gallery_overview {
    margin-bottom: 1.5em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #f3f3f3;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry .open {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.75em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #ED7E00;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #ED7E00;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #ED7E00;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #5a5a5a;
    height: 2rem;
    width: 2rem;
  }
  .kanzlei_im_netz {
    margin: 0 0 1.5em;
  }
  .kanzlei_im_netz a:after {
    clear: both;
  }
  .kanzlei_im_netz a:after,
  .kanzlei_im_netz a:before {
    content: "";
    display: table;
  }
  .kanzlei_im_netz .icon {
    border-radius: 0 0 0.75rem 0;
    height: 3.625rem;
    margin: 0 1.5em 0 0;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_mobile,
  .kanzlei_im_netz .icon_mail,
  .kanzlei_im_netz .icon_video {
    background: black;
    border-radius: 100%;
    fill: white;
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon_mobile svg,
  .kanzlei_im_netz .icon_mail svg,
  .kanzlei_im_netz .icon_video svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .icon_mail {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    fill: white;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .kanzlei_im_netz .icon_mail svg {
    height: 2.1875rem;
    width: 2.1875rem;
  }
  .kanzlei_im_netz .icon.icon_online svg {
    height: 3.875rem;
    width: 3.875rem;
  }
  .kanzlei_im_netz .link_head {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin: 1.5em 0 0.75em 0;
  }
  .kanzlei_im_netz a.touch_link .title {
    margin: 0;
    text-decoration: none;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  main {
    padding-top: 3em;
    position: relative;
  }
  .startpage main {
    margin-bottom: 4.5em;
    padding-top: 3em;
  }
  .bottom_padding {
    padding-bottom: 1.5em;
  }
  .margin-top {
    margin-top: 1.5em;
  }
  figure.padding_right {
    padding-right: 3em;
  }
  .no_top_padding {
    padding-top: 0;
  }
  .no_bottom_margin {
    margin-bottom: 0;
  }
  .lightgray_background {
    background-color: #EDEDED;
    padding: 1em;
  }
  .lightgray_background a {
    color: #5a5a5a;
  }
  .figure_overview {
    margin-top: 3em;
  }
  .figure_overview figure {
    margin-bottom: 3em;
  }
  .cooperation_partner {
    margin-bottom: 1.5em;
    position: relative;
  }
  .cooperation_partner_entry {
    border-top: 0.3125rem solid #EDEDED;
    padding-top: 1.5em;
  }
  .cooperation_partner_title {
    padding-top: 0;
  }
  figure.partner {
    margin-bottom: 3em;
    max-width: none !important;
    text-align: center;
  }
  figure.partner a {
    display: block;
    text-align: center;
  }
  figure.partner img {
    display: inline;
  }
  figure.partner figcaption {
    text-align: center;
  }
  .events_review_overview .entry {
    margin-bottom: 1.5em;
  }
  .event_container > .entry > a {
    display: block;
  }
  .event_search {
    margin-bottom: 3em;
  }
  .event_search h2 {
    padding-top: 0;
  }
  .event_search_input {
    margin: 0 auto;
    max-width: 80ch;
    text-align: center;
  }
  .event_content .event_header {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-bottom: 1.5em;
  }
  .event_content .event_header h2 {
    margin: 0;
    padding: 0;
  }
  .event_content .date {
    align-content: center;
    background: white;
    border: 0.0625rem solid #ED7E00;
    border-radius: 0 0 1.125rem 0;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    height: 5.0625rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.75em;
    position: relative;
    width: 5.0625rem;
    z-index: 20;
  }
  .event_content .date span {
    display: block;
    text-align: center;
  }
  .event_content .day {
    font-size: 250%;
    font-weight: bold;
    line-height: 1;
  }
  .event_content .month_year {
    font-size: 80%;
  }
  .event_content .event_tag {
    display: inline-block;
    font-weight: bold;
    min-width: 4.375rem;
  }
  .event_content .buttons {
    margin-top: 3em;
  }
  .date_ics {
    display: block;
  }
  .date_ics a {
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
  }
  .date_ics a:before {
    border: 0 solid #ED7E00;
    border-bottom-width: 0.3125rem;
    border-right-width: 0.3125rem;
    content: "";
    display: block;
    flex-shrink: 0;
    height: 0.75rem;
    margin: 0.375em 0.375em 0 -0.375em;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: margin 300ms;
    transition: margin 300ms;
    width: 0.75rem;
  }
  .date_ics a:hover {
    text-decoration: underline;
  }
  .date_ics a:hover:before {
    margin: 0.375em 0 0 0;
  }
  .date_ics a span {
    color: #5a5a5a;
    display: block;
    margin-left: 0.5em;
  }
  .startpage .marginal_column h2 {
    color: #ED7E00;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    padding-top: 0;
  }
  .startpage .marginal_column h2 a {
    color: #ED7E00;
    display: block;
    text-decoration: none;
  }
  .startpage .marginal_column h2 a:before {
    border-right: 0.20833333rem solid #999999;
    border-top: 0.20833333rem solid #999999;
    content: " ";
    display: inline-block;
    height: 0.375rem;
    margin: 0 0.25em 0.125rem 0;
    position: relative;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 0.375rem;
  }
  .startpage .marginal_column h2 a:hover:before {
    -webkit-transform: scale(1.25) rotate(45deg);
            transform: scale(1.25) rotate(45deg);
  }
  .startpage .darkgray_top_border:before {
    border-top: 0.3125rem solid #5a5a5a;
    content: "";
    display: block;
    padding-top: 0.75em;
  }
  .startpage .lightgray_top_border:before {
    border-top: 0.3125rem solid #EDEDED;
    content: "";
    display: block;
    padding-top: 0.75em;
  }
  .startpage .lightgray_top_border h3,
  .startpage .lightgray_top_border .style_h2 {
    padding-top: 0;
  }
  .startpage .lightgray_top_border h1 {
    color: #477A8E;
  }
  .startpage .no_top_border .lightgray_top_border:before {
    display: none;
  }
  .startpage .start_sections > .entry {
    margin-bottom: 3em;
  }
  .persaldo_start .figure {
    border: 0.0625rem solid #EDEDED;
  }
  .youtube_video {
    margin-bottom: 1.5em;
    padding-bottom: 55.37%;
    position: relative;
  }
  .youtube_video iframe {
    bottom: 0;
    height: 100% !important;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;
  }
  .event_locations_overview h2 {
    padding-top: 0;
    text-align: center;
  }
  .event_locations_overview ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    list-style: none !important;
    padding-left: 0;
  }
  .event_locations_overview ul li {
    margin-right: 0.75em;
    padding-left: 0;
    width: 100%;
  }
  .event_locations_overview ul li a.button.ghost_button {
    display: -webkit-box;
    display: flex;
  }
  .free_downloads .file,
  .free_downloads .audio {
    height: auto;
    margin: 0;
  }
  .free_downloads .icon {
    display: block;
    fill: #ED7E00;
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.375em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .free_downloads .downloads {
    display: -webkit-box;
    display: flex;
  }
  .free_downloads .downloads .file,
  .free_downloads .downloads .audio {
    flex-basis: 0;
  }
  .free_downloads .downloads .file .text,
  .free_downloads .downloads .audio .text {
    display: none;
  }
  .overview_musterschrift ul {
    list-style: none !important;
    padding-left: 0;
  }
  .overview_musterschrift ul li {
    padding: 0.1875rem 0.875rem 0.3125rem;
  }
  .overview_musterschrift ul li:nth-child(odd) {
    background: #EDEDED;
  }
  .overview_musterschrift ul li.top {
    background: #c7c7c7;
    text-align: right;
  }
  .overview_musterschrift ul li.top .index_z {
    display: inline-block;
    text-align: left;
    width: 2.5rem;
  }
  .overview_musterschrift ul li a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    text-decoration: none;
  }
  .overview_musterschrift ul li a span.left_column {
    padding-right: 1.5em;
  }
  .overview_musterschrift ul li a span.left_column .index_title {
    text-decoration: underline;
  }
  .overview_musterschrift ul li a span.index_z {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    width: 2.5rem;
  }
  .overview_musterschrift ul li a:hover {
    color: #5a5a5a;
  }
  .musterschrift_aside {
    list-style: none !important;
    padding-left: 0;
  }
  .musterschrift_aside li {
    margin-bottom: 0.75em;
    padding-left: 0;
  }
  .musterschrift_aside a {
    color: #5a5a5a;
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
  }
  .musterschrift_aside a:before {
    border: 0 solid #a7a7a7;
    border-bottom-width: 0.3125rem;
    border-right-width: 0.3125rem;
    content: "";
    display: block;
    flex-shrink: 0;
    height: 0.625rem;
    margin: 0.375em 0.375em 0 -0.375em;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: margin 300ms, border-color 300ms;
    transition: margin 300ms, border-color 300ms;
    width: 0.625rem;
  }
  .musterschrift_aside a.active {
    color: #ED7E00;
  }
  .musterschrift_aside a.active:before {
    border-color: #ED7E00;
  }
  .musterschrift_aside a:hover {
    color: #ED7E00;
    text-decoration: underline;
  }
  .musterschrift_aside a:hover:before {
    border-color: #ED7E00;
    margin: 0.375em 0 0 0;
  }
  .musterschrift_aside a span {
    display: block;
    margin-left: 0.5em;
  }
  .enter_download_code input,
  .script_search_input {
    max-width: 18.75rem;
  }
  .changing_sujet {
    background-position: center;
    background-size: cover;
    padding-bottom: 40%;
    position: relative;
  }
  .startpage .changing_sujet {
    padding-bottom: 60%;
  }
  .down_arrow {
    display: none;
    height: 6.5rem;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
    width: 6.5rem;
  }
  .down_arrow:hover {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .start_news > ul {
    background: white;
    margin: -1.5em 0 1.5em 0;
    padding: 0;
  }
  .start_news > ul li {
    list-style: none !important;
    padding: 0;
    position: relative;
  }
  .start_news > ul li:nth-child(2) .start_news_entry {
    background: #d1dde3;
  }
  .start_news > ul li:nth-child(3) .start_news_entry {
    background: #ededed;
  }
  .start_news > ul li:nth-child(4) .start_news_entry {
    background: #d1dde3;
  }
  .start_news > ul .start_news_entry {
    -webkit-box-align: center;
            align-items: center;
    background: #ededed;
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.75em;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    width: 100%;
  }
  .start_news > ul .start_news_entry[href="#"] {
    pointer-events: none;
  }
  .start_news > ul .start_news_entry:hover {
    background: #5a5a5a;
    color: white;
  }
  .start_news > ul .description {
    display: none;
    overflow: hidden;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
  }
  .start_news > ul .description h2 {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .start_news > ul .description_content {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 0.75rem 0;
    padding: 1.5em;
  }
  .start_news > ul .description_content .button {
    margin-bottom: 0;
  }
  .start_news .progress_bar {
    border-top: 0.3125rem solid #ED7E00;
    content: "";
    display: none;
    height: 0.3125rem;
    left: -1.5em;
    position: absolute;
    right: -1.5em;
    top: -0.3125rem;
  }
  .login_page .login_prompt {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 3em;
  }
  .login_page .login_prompt label {
    display: block;
  }
  .login_page .login_prompt .button,
  .login_page .login_prompt input {
    width: 100%;
  }
  .login_page .login_prompt > div {
    max-width: 25rem;
  }
  .login_small .header_content {
    background: transparent;
  }
  .wahl_2025 .oegsw_alle_bundeslaender .figure img {
    border-radius: 50%;
  }
  .wahl_2025 .oegsw_alle_bundeslaender .kandidat_stimme {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .wahl_2025 .oegsw_alle_bundeslaender .kandidat_stimme h3 {
    color: #ED7E00;
    margin-bottom: 0;
  }
  .wahl_2025 .oegsw_alle_bundeslaender .zms_folder:nth-child(even) .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .wahl_2025 .oegsw_alle_bundeslaender .zms_folder .row div {
    text-align: -webkit-center;
  }
  .wahl_2025 .folder_margin {
    margin-top: 1.5em;
  }
  .wahl_2025 .section_image .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
  }
  .wahl_2025 .section_image .row img {
    max-width: 25rem;
  }
  .wahl_2025 .large_enumeration ol li {
    padding-left: 0;
  }
  .wahl_2025 .large_enumeration ol li:before {
    font-size: 1.125rem;
    padding: 1em;
    background-color: #ED7E00;
    color: #fff;
    border: 0.0625rem solid #EDEDED;
    border-radius: 0 0 0.75rem 0;
  }
  .wahl_2025 .large_enumeration ol li p {
    font-size: 1.125rem;
    padding: 1em;
    padding-left: 4.5em;
    background-color: #f3f3f3;
  }
  .toggle_offcanvas_menu {
    background: #ED7E00;
    border-bottom-left-radius: 0.875rem;
    bottom: 0;
    display: inline-block;
    left: 0;
    margin: 0 0 -5% 1.5em;
    padding: 0 1.5em 0 0.75em;
    position: absolute;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    color: black;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
    font-size: 112.5%;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin: 0 0.75em 0 0.375em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.875rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    border-radius: 0.25rem;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line:before {
    background: #fff;
    border-radius: 0.25rem;
    content: "";
    height: 0.25rem;
    left: -0.375rem;
    position: absolute;
    top: 0;
    width: 0.25rem;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
    width: 70%;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    width: 85%;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(90, 90, 90, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    background: #EDEDED;
    border: 0.3125rem solid #EDEDED;
    margin: 0.375em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #5a5a5a;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 2.75rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #ED7E00;
  }
  .offcanvas_menu .offcanvas_menu_content > ul a {
    padding: 0 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded {
    border-color: #d4d4d4;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    padding-bottom: 0.75em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #5a5a5a;
    display: block;
    padding: 0.375em 1.5em 0.375em 1.5em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #ED7E00;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.0625rem solid #a7a7a7;
    border-top: 0.0625rem solid #a7a7a7;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #ED7E00;
    border-radius: 0 0 0.75rem 0 0 0 0.75rem 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 14.375rem;
    margin: 0 auto;
    max-width: 26.25rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #f3f3f3;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(243, 243, 243, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 0 0 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    border-radius: 0 0 0.75rem 0;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #ff9721;
    border: 0.0625rem solid #ff9721;
    box-shadow: 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #ff9721;
    border: 0.0625rem solid #ff9721;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map form {
    border: 0.0625rem solid #5a5a5a;
    margin: 1.5em 0 0 0;
  }
  .open_street_map form fieldset {
    padding: 0 1.5em;
  }
  .open_street_map .form_disclaimer {
    background: #f3f3f3;
    color: #5a5a5a;
    margin-bottom: 0;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #a7a7a7;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0.75rem 0 0 0 0.75rem 0 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  form[role="search"] button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 700;
    padding: 0.375em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #f3f3f3;
    border-color: #e6e6e6;
    color: #dadada;
  }
  form[role="search"] button[disabled] .icon {
    fill: #5a5a5a;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    position: relative;
    z-index: 10;
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: 2.625rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #a7a7a7;
    border-radius: 0 0 0.75rem 0 0 0 0 0 0.75rem 0;
    box-shadow: 0 0 0 #a7a7a7 inset;
    color: #5a5a5a;
    flex-basis: 100%;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #ED7E00;
    box-shadow: 0 0 0.1875rem #ED7E00 inset, 0 0 0 0.1875rem rgba(237, 126, 0, 0.2);
    color: #5a5a5a;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    height: 3.375rem;
    padding: 0.625rem;
    position: relative;
    width: 3.375rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #ED7E00;
  }
  .toggle_search_box a {
    fill: #fff;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(90, 90, 90, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #ED7E00;
    border: 0.0625rem solid #ED7E00;
    border-radius: 0 0 0.75rem 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #ff9721;
    border-color: #ff9721;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #ff9721;
    border-color: #ff9721;
    color: #fff;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #fff;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0 0 0.75rem 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  .sitemap .sitemap_entry {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .sitemap ul a {
    color: #5a5a5a;
  }
  .social_media_legal_notice {
    padding: 1.5em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .social_media_links {
    background: #3E3E3E;
    display: none;
    left: 0;
    padding: 0.53125rem;
    position: absolute;
    top: 9em;
    /*
    @media (orientation: landscape) {
        top: 50vh;
        transform: translate(0, -40%);
    }
*/
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .social_media_links a {
    border-radius: 0 0 0.75rem 0;
    display: inline-block;
    padding: 0.53125rem 1.0625rem 0.53125rem 1.0625rem;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.facebook .icon,
  .social_media_links a.twitter .icon {
    border-radius: 0;
  }
  .social_media_links a:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    line-height: 0;
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #5a5a5a;
    cursor: pointer;
    display: block;
    padding-right: 1.0625rem;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a:hover {
    text-decoration: underline;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0 0 0.75rem 0;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 1.0625rem;
    padding-right: 0.75em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: black;
    border-radius: 100%;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a.facebook .icon,
  .social_media_share a.twitter .icon {
    border-radius: 0;
  }
  .social_media_share a.whatsapp {
    display: none;
  }
  .social_media_share a.whatsapp .icon {
    border-radius: 100%;
    overflow: hidden;
  }
  .tablet .social_media_share a.whatsapp,
  .mobile .social_media_share a.whatsapp {
    display: block;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #f3f3f3;
    border-radius: 0 0 0.75rem 0;
    display: inline-block;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .steuernews_current_issue ul {
    list-style: url("../images/list_style_image_rectangle.svg") !important;
    padding-left: 1.125em;
  }
  .steuernews_current_issue ul > li {
    padding-left: 0.375em;
  }
  .steuernews_current_issue a {
    color: #5a5a5a;
    text-decoration: none;
  }
  .steuernews_current_issue a:hover {
    text-decoration: underline;
  }
  .steuernews_current_issue a.active {
    color: #ED7E00;
  }
  .steuernews_article_slider {
    display: -webkit-box;
    display: flex;
    padding: 0 0.9375rem;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .steuernews_article_slider .steuernews_article_slider_container {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .steuernews_article_slider .slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    left: 0;
    position: absolute;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    position: relative;
  }
  .steuernews_article_slider .slide .image {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    max-width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75em 0;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    word-wrap: break-word;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .control_slides {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    fill: #5a5a5a;
    height: 0;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 30%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: none;
    width: 0;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides:before {
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: width 300ms;
    transition: width 300ms;
    width: 1.875rem;
  }
  .steuernews_article_slider .control_slides.show {
    height: 2.75rem;
    opacity: 1;
    visibility: visible;
    width: 2.75rem;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #ED7E00;
    -webkit-box-pack: center;
            justify-content: center;
    opacity: 1;
    outline: 0;
  }
  .steuernews_article_slider .control_slides:focus:before,
  .steuernews_article_slider .control_slides:hover:before {
    width: 100%;
  }
  .steuernews_article_slider .control_slides .icon {
    height: 1.25rem;
    position: absolute;
    width: 1.25rem;
    right: 0.3125rem;
    -webkit-transition: right 300ms;
    transition: right 300ms;
  }
  .steuernews_article_slider .control_slides:focus.show .icon,
  .steuernews_article_slider .control_slides:hover.show .icon {
    right: calc(50% - 0.625rem);
  }
  .steuernews_article_slider .prev_slide {
    left: -0.9375rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: -0.9375rem;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 0.75em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .teamoverview .row {
    margin: 0 -0.125rem;
  }
  .teamoverview [class*="span"] {
    padding: 0 0.125rem 0.25rem 0.125rem;
  }
  .teamoverview .team_entry {
    background-size: cover;
    border-radius: 0 0 0.75rem 0;
    display: block;
    padding-bottom: 100%;
    position: relative;
    -webkit-transition: z-index 300ms, -webkit-transform 300ms;
    transition: z-index 300ms, -webkit-transform 300ms;
    transition: transform 300ms, z-index 300ms;
    transition: transform 300ms, z-index 300ms, -webkit-transform 300ms;
    z-index: 1;
  }
  .teamoverview .team_entry:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    z-index: 100;
  }
  .teamoverview .team_entry:hover .team_text {
    opacity: 1;
  }
  .teamoverview .team_text {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    border: 0.15625rem solid #ED7E00;
    border-radius: 0 0 0.75rem 0;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .share_links_state {
    display: none;
  }
  .share_links_state:checked ~ .social_media_links,
  .share_links_state:target ~ .social_media_links {
    display: block;
  }
  .toggle_share_links label {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-family: "UbuntuCondensed", "Arial Narrow", Arial, sans-serif;
    font-size: 112.5%;
    padding: 0.625rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_share_links label .icon {
    fill: #fff;
    height: 2.125rem;
    position: relative;
    width: 2.125rem;
  }
  .toggle_share_links label .text {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f3f3f3;
    border: 0.0625rem solid #a7a7a7;
    border-radius: 0 0 0.75rem 0;
    bottom: 100%;
    color: #5a5a5a;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f3f3f3;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #a7a7a7;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
  }
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0.75em 0;
  }
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #ED7E00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #ED7E00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #ED7E00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .wahl_video .video_player h2 {
    padding-top: 0;
  }
  .wahl_video .video_player .video_wrapper {
    height: auto;
    max-width: 20rem;
    padding-top: 0;
  }
  .wahl_video .video_player .video_wrapper + p {
    display: none;
  }
  .wahl_video .video_player .video_wrapper video {
    position: relative;
    height: auto;
  }
  .wahlvideo_kandidat .video_wrapper + p {
    display: none;
  }
  .wahlvideo_kandidat.no_title h2 {
    display: none;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_player.hide_title [itemprop="name"],
  .video_player.hide_description [itemprop="description"] {
    display: none;
  }
  .accordion .video_wrapper video {
    border: 0.0625rem solid #ededed;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper .overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: #f3f3f3;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 1.5em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 1;
  }
  .video_wrapper .overlay .text_wrapper a {
    display: block;
  }
  .video_wrapper.show_message {
    min-height: 18.75rem;
  }
  .video_wrapper.show_message .overlay {
    opacity: 1;
    visibility: visible;
  }
  .video_wrapper .text_wrapper {
    font-size: 85%;
    line-height: normal;
    overflow-y: auto;
    width: 100%;
  }
  .video_wrapper .button {
    margin-top: 1.5em;
  }
}
@media screen and (min-width: 93.75rem) {
  .calendar_days {
    margin-right: 3em;
  }
}
@media screen and (min-width: 47.5rem) {
  .calendar_days {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .events_overview.calendar {
    bottom: 0;
    left: 1.5625rem;
    overflow: auto;
    overflow-y: scroll;
    padding-top: 0;
    position: absolute;
    right: 1.5625rem;
    top: 0;
  }
  .events_overview.calendar > div.show_desktop {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 20rem) {
  .events_overview .entry h3 .small_title {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .events_overview .entry h3 .small_title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .transcripts_audiofiles_entry {
    border: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .transcripts_audiofiles_entry ul {
    margin-left: -0.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .transcripts_audiofiles_entry .seminar_documents_image {
    display: block;
  }
}
@media screen and (hover: hover) {
  .transcripts_audiofiles_entry ul {
    display: -webkit-box;
    display: flex;
  }
  .transcripts_audiofiles_entry .text {
    display: none;
  }
  .transcripts_audiofiles_entry p.description {
    display: block;
  }
}
@media screen and (min-width: 35rem) {
  .survey_widget {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .survey_result,
  .survey_form,
  .survey_widget .info {
    width: 50%;
  }
}
@media screen and (min-width: 35rem) {
  .info {
    margin-right: 2rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .webinar_entry {
    border: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .webinar_entry ul {
    margin-left: -0.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .webinar_entry .webinar_image {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  aside {
    padding-top: 6em;
  }
}
@media screen and (min-width: 20rem) {
  .author_ver {
    left: calc(0.1875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_ver {
    left: 0.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .author_ver {
    top: calc(0.3125rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_ver {
    top: 0.4375rem;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button.ghost_button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button.ghost_button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button.ghost_button:active > span,
  .button.ghost_button.active > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button.ghost_button:active > span,
  .button.ghost_button.active > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button_overview .entry.touch_link .title {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button_overview .entry.touch_link .title {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button_overview .link_entry {
    line-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button_overview .link_entry {
    line-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    width: calc(6.875rem + 4.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    width: 11.375rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    margin-bottom: -4.75rem;
  }
}
@media screen and (min-width: 67.5rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 75rem) {
  footer[role="contentinfo"] .dark_background {
    text-align: left;
  }
}
@media screen and (min-width: 61.875rem) {
  footer[role="contentinfo"] .footer_box {
    border-left: 0.3125rem solid #595959;
    border-top: 0;
    padding-top: 0;
  }
}
@media screen and (min-width: 75rem) {
  footer[role="contentinfo"] .contact_wrapper p {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (min-width: 47.5rem) {
  footer[role="contentinfo"] .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  footer[role="contentinfo"] .related_links ul li {
    padding: 0 1em;
    position: relative;
  }
  footer[role="contentinfo"] .related_links ul li:after {
    border-right: 0.0625rem solid #5a5a5a;
    bottom: 0.25em;
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0.25em;
  }
  footer[role="contentinfo"] .related_links ul li:last-child:after {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) and (min-height: 54.375rem) {
  body > header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
}
@media screen and (min-width: 30rem) {
  .header_container {
    -webkit-box-align: center;
            align-items: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_container {
    -webkit-box-align: end;
            align-items: flex-end;
  }
}
@media screen and (min-width: 30rem) {
  .functions_menu {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .login_link {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_content {
    min-height: 17.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .wahl_banner {
    position: absolute;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    height: 6em;
  }
}
@media screen and (min-width: 20rem) {
  .wahl_banner .headline h2 {
    font-size: calc(1.5rem + 1
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .wahl_banner .headline h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .wahl_banner .headline h2 .text_white {
    font-size: calc(2rem + 1.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .wahl_banner .headline h2 .text_white {
    font-size: 3.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .wahl_banner .dark_button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  main {
    padding-top: 6em;
  }
}
@media screen and (min-width: 61.875rem) {
  .sidebar_image_alignright {
    margin-left: auto;
  }
}
@media screen and (min-width: 47.5rem) {
  .lightgray_background {
    margin-left: -1.5em;
    margin-right: -1.5em;
    padding: 1em 1.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .no_header h1 {
    left: 50%;
    max-width: 62ch;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%, -6.25rem);
            transform: translate(-50%, -6.25rem);
  }
  .no_header .breadcrumbs {
    display: none;
  }
  .no_header.news_page h1 {
    left: auto;
    max-width: none;
    position: relative;
    top: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .no_header.news_page .breadcrumbs {
    display: block;
  }
  .no_header.news_page .news_category {
    left: 50%;
    max-width: 62ch;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%, -6.25rem);
            transform: translate(-50%, -6.25rem);
  }
}
@media screen and (min-width: 30rem) {
  figure.partner {
    display: inline-block;
    padding: 0 0.75em;
    width: 45%;
  }
}
@media screen and (min-width: 47.5rem) {
  figure.partner {
    width: 28%;
  }
}
@media screen and (min-width: 75rem) {
  figure.partner {
    width: 18%;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .marginal_column {
    text-align: right;
  }
}
@media screen and (min-width: 61.875rem) {
  .free_downloads [role="tabpanel"] {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 61.875rem) {
  .free_downloads .download {
    flex-basis: 50%;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 30rem) {
  .overview_musterschrift ul li.top .index_z {
    width: 5.3125rem;
  }
}
@media screen and (min-width: 30rem) {
  .overview_musterschrift ul li a span.left_column {
    display: -webkit-box;
    display: flex;
  }
  .overview_musterschrift ul li a span.left_column .index_nr {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    width: 3.125rem;
  }
}
@media screen and (min-width: 30rem) {
  .overview_musterschrift ul li a span.index_z {
    width: 5.3125rem;
  }
}
@media screen and (orientation: landscape) and (min-width: 61.875rem) {
  .changing_sujet {
    min-height: 21.875rem;
    padding-bottom: 30%;
  }
}
@media screen and (orientation: landscape) and (min-device-width: 85.4375rem) {
  .startpage .changing_sujet {
    background-attachment: fixed;
    height: 100vh;
    padding: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .down_arrow {
    background: white;
    border-radius: 100%;
    bottom: 10vh;
    display: block;
    opacity: 0.8;
    padding: 1.2em 0 0 0;
    position: absolute;
    right: 10vw;
  }
  .down_arrow:before {
    border-bottom: 0.625rem solid #ED7E00;
    border-right: 0.625rem solid #ED7E00;
    content: "";
    display: block;
    height: 1.875rem;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 1.875rem;
  }
  .down_arrow .text {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .start_news > ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    height: 3em;
    left: -1.5em;
    margin-top: -12em;
    position: absolute;
    right: -1.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .start_news > ul li {
    display: -webkit-box;
    display: flex;
    width: 25%;
  }
}
@media screen and (min-width: 61.875rem) {
  .start_news > ul li:hover .start_news_entry,
  .start_news > ul li.active .start_news_entry {
    background: #5a5a5a;
    color: white;
  }
  .start_news > ul li:hover .description,
  .start_news > ul li.active .description {
    height: auto;
    opacity: 1;
    padding-bottom: 3em;
  }
}
@media screen and (min-width: 47.5rem) {
  .start_news > ul .description {
    display: block;
    height: 0;
    opacity: 0;
    position: absolute;
  }
}
@media screen and (min-width: 47.5rem) {
  .start_news > ul .description {
    bottom: 3em;
    left: -0.375em;
    right: -0.375em;
  }
}
@media screen and (min-width: 93.75rem) {
  .start_news > ul .description {
    left: -1.125em;
    right: -1.125em;
  }
}
@media screen and (min-width: 47.5rem) {
  .start_news > ul .description_content .button_container {
    margin: 0 -1.5em -1.5em auto;
    text-align: right;
  }
}
@media screen and (min-width: 61.875rem) {
  .start_news .progress_bar {
    display: block;
    top: -9.234375em;
  }
}
@media screen and (min-width: 47.5rem) {
  .login_small .header_content {
    min-height: 6.6875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .wahl_2025 .section_image .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    max-width: 100%;
  }
  .wahl_2025 .section_image .row img {
    padding-top: 50%;
  }
}
@media screen and (min-width: 61.875rem) {
  .wahl_2025 .large_enumeration ol li:before {
    font-size: 1.35rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .wahl_2025 .large_enumeration ol li p {
    font-size: 1.35rem;
  }
}
@media screen and (min-width: 30rem) {
  .toggle_offcanvas_menu {
    bottom: auto;
    height: 2.75rem;
    left: auto;
    margin-bottom: 0;
    position: relative;
  }
}
@media screen and (min-width: 67.5rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 67.4375rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line:before,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line:before,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line:before,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line:before {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
    width: 100%;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
    width: 100%;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  input[type="search"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_search_box {
    height: 2.75rem;
    padding: 0 1.25rem 0 0;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .sitemap {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (min-width: 61.875rem) {
  .sitemap {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links {
    display: block;
    padding: 0.53125rem 0;
    top: 9em;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider {
    padding: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide .description {
    padding: 0 4.5em 0 3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .control_slides {
    top: 50%;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_article_slider .control_slides:before {
    background: #f3f3f3;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_article_slider .control_slides:before {
    width: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .control_slides:before {
    width: calc(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)) - 0.5rem);
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_article_slider .control_slides:before {
    width: 2.75rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_article_slider .control_slides .icon {
    right: calc(50% - 0.625rem);
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_article_slider .control_slides:focus.show .icon,
  .steuernews_article_slider .control_slides:hover.show .icon {
    right: 0.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_article_slider .prev_slide {
    left: calc(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)) * -1);
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_article_slider .prev_slide {
    left: -3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_article_slider .next_slide {
    right: calc(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)) * -1);
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_article_slider .next_slide {
    right: -3.125rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 30rem) {
  .teamoverview .team_text {
    font-size: 70%;
  }
}
@media screen and (min-width: 93.75rem) {
  .teamoverview .team_text {
    font-size: 85%;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_share_links {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_container .social_media_links {
    display: none;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 11.25rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .events_overview.calendar .content {
    margin-bottom: 1.5em;
  }
  .events_overview.calendar strong {
    display: block;
  }
  .events_overview a {
    text-decoration: none;
  }
  .download_overview .touch_link .title_wrapper,
  .accordion.events_overview .topic {
    font-family: "TexGyreHeros", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 0;
  }
  .accordion.events_overview .topic {
    font-weight: bold;
  }
  .accordion.events_overview .topic .small_title {
    font-weight: normal;
  }
  .accordion.events_overview .content,
  .team_entry {
    display: block;
    margin-bottom: 1.5em;
  }
  main .download_overview a.touch_link {
    margin-bottom: 0;
  }
  .events_review_overview .entry {
    margin-bottom: 1.5em;
  }
  .events_review_overview img {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
